import React, { Component } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import BlueSignPopup from './BlueSignPopup.js'
import LanguageSelector from './LanguageSelector.js'
import "./KnabenMap.css";
const tilesAttribution = '<a href="http://www.kartverket.no/">Kartverket</a>';

export default class KnabenMap extends Component {

    render() {
        const tiles = this.props.tiles;
        let tilesUrl = "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart_graatone&zoom={z}&x={x}&y={y}";
        if (tiles === "topo") {
            tilesUrl = "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}";
        }

        const L = require('leaflet');
        const iconHouse = L.icon({
          iconUrl: require('./house.svg'),
          iconSize: [32,32],
          iconAnchor: [16,16],
      });
        const blueSigns = this.props.blueSigns;
        return (
            <div>
                <Map center={[58.6627039, 7.0700606]} zoom={15} maxZoom={18}>
                    <TileLayer attribution={tilesAttribution} url={tilesUrl} />
                    {blueSigns.map((blueSign, idx) =>
                          <Marker key={`marker-${idx}`} position={blueSign.position} icon={iconHouse}>
                              <BlueSignPopup blueSign={blueSign} />
                          </Marker>
                    )}
                    <LanguageSelector selectedLanguage={this.props.selectedLanguage} setLanguageCallback={this.props.setLanguageCallback}/>
                </Map>

            </div>
        );
    }
}