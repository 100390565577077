import React, { Component } from 'react';
import { Popup } from 'react-leaflet';
import "./BlueSignPopup.css";
import {
    translate
} from 'react-switch-lang';

class BlueSignPopup extends Component {

    render() {
        const blueSign = this.props.blueSign;
        const titleKey = "blueSigns." + blueSign.id + ".title";
        const descriptionKey = "blueSigns." + blueSign.id + ".description";
        const t = this.props.t;
        return (
            <Popup className="blue-sign-popup">
                {
                    blueSign.thumbnail &&
                    <div>
                        <img src={blueSign.thumbnail} alt="House"/>
                        <br />
                    </div>
                }
                <span className="header">{t(titleKey)}</span>
                <br />
                <div className="text">
                    {t(descriptionKey)}
                </div>
            </Popup>
        );
    }
}

export default translate(BlueSignPopup);