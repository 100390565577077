const dev = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "uncreated-api-dev-attachmentsbucket-pzb6qgf6p447"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://m2go31b8h3.execute-api.eu-west-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_1ojyM4QpE",
    APP_CLIENT_ID: "19k72phpneq31nctv1a9u5j4bk",
    IDENTITY_POOL_ID: "eu-west-1:9b510c3c-8aca-4a0a-87f5-58f69c507ce4"
  }
};

const prod = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "uncreated-api-prod-attachmentsbucket-ql2z87kd9749"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://1pagnr43ql.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_QDWGwNf37",
    APP_CLIENT_ID: "41ema7nsst5qf48up7uh9tpmt4",
    IDENTITY_POOL_ID: "eu-west-1:ea88ee06-bda5-464b-a29e-78afd52866c0"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
