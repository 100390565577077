import React, { Component } from "react";
import "./Home.css";
import KnabenMap from "../components/KnabenMap.js";
//import TilesSelector from "../components/TilesSelector.js";
import blueSigns from '../data/blue-signs.json';
import {
  setLanguage
} from 'react-switch-lang';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
        tiles: "gray",
        blueSigns: blueSigns,
        selectedLanguage: "norwegian"
    };

    this.setTiles = this.setTiles.bind(this);
    this.languageChanged = this.languageChanged.bind(this);
  }

  async componentDidMount() {

  }

  renderLander() {
    const tiles = this.state.tiles;
    const blueSigns = this.state.blueSigns;
    return (
      <div className="lander">
        <KnabenMap tiles={tiles} blueSigns={blueSigns} selectedLanguage={this.state.selectedLanguage} setLanguageCallback={this.languageChanged}/>
      </div>
    );
  }

  setTiles(selectedTiles) {
    this.setState({
        tiles: selectedTiles
    });
  }

  render() {
    return (
      <div className="Home">
        {this.renderLander()}
      </div>
    );
  }

  languageChanged(language) {
    this.setState({
        selectedLanguage: language
    });
    setLanguage(language);
  }
}