import React, { Component } from 'react';
import './LanguageSelector.css';

export default class LanguageSelector extends Component {

    render() {
        const selectedLanguage = this.props.selectedLanguage;
        const norwegianStyle = "language-flag" + (selectedLanguage === "norwegian" ? " selected-language" : "");
        const englishStyle = "language-flag" + (selectedLanguage === "english" ? " selected-language" : "");
        const germanStyle = "language-flag" + (selectedLanguage === "german" ? " selected-language" : "");
        return (
            <div className="language-selector">
                <img className={norwegianStyle} src="assets/img/flags/norway.svg" alt="Norsk" onClick={() => this.props.setLanguageCallback("norwegian")}/>
                <img className={englishStyle} src="assets/img/flags/united-kingdom.svg" alt="English" onClick={() => this.props.setLanguageCallback("english")} />
                <img className={germanStyle} src="assets/img/flags/germany.svg" alt="Deutch" onClick={() => this.props.setLanguageCallback("german")} />
            </div>
        );
    }
}