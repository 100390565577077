import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import {
  setTranslations,
  setDefaultLanguage,
  translate,
} from 'react-switch-lang';
import norwegian from './lang/norwegian.json';
import english from './lang/english.json';
import german from './lang/german.json';
setTranslations({ norwegian, english, german });
setDefaultLanguage('norwegian');

class App extends Component {

    async componentDidMount() {

    }

    render() {
        return (
          <div className="App">
            <Routes />
          </div>
    );
  }

}

export default translate(withRouter(App));